<app-navigation-accounts-select
    *ngIf="clientList"
    [accountList]="clientList">
</app-navigation-accounts-select>
<app-navigation-logout-timer></app-navigation-logout-timer>
<app-navigation-network-status></app-navigation-network-status>

<app-navigation-news></app-navigation-news>

<div class="sidebar customScrollbar">
  <ion-list lines="none">
    <ion-item
        *ngIf="notifications && notifications.length"
        (click)="onConfirmTransferToAnyRequest()"
        class="SendMoneyRequest-banner">
      <ion-icon slot="start" name="icon-eur-png"></ion-icon>
      <ion-label>{{ 'home.tenth_button' | translate }}</ion-label>
      <ion-badge slot="end" class="ion-text-center">
        {{ notifications.length }}
      </ion-badge>
      <ion-icon slot="end" name="arrow-right-small-white" class="SendMoneyRequest-banner__arrow"></ion-icon>
    </ion-item>

    <ion-button
        routerLink="/top-up-account"
        *appPermission="[ roles.BCC_LEGAL_PERSON, roles.BCC_INDIVIDUAL_PERSON, roles.GEKKARD ]"
        shape="round"
        fill="outline"
        color="dark"
        expand="block">
      <ion-icon slot="start" name="top-up-account"></ion-icon>
      <ion-skeleton-text *appLocalizationNotLoaded [animated]="true" style="width: 100%"></ion-skeleton-text>
      <span *appLocalizationIsLoaded>{{ 'home.first_button' | translate }}</span>
    </ion-button>

    <ion-menu-toggle auto-hide="false">
      <ion-item
          *ngFor="let item of mainNavItemList"
          routerDirection="root"
          routerLinkActive="active"
          [routerLink]="[item.url]">
        <ion-icon class="sidebar-icon" slot="start" [name]="item.iconName"></ion-icon>
        <ion-icon class="sidebar-icon-active" slot="start" [name]="item.iconName + '-green'"></ion-icon>
        <ion-skeleton-text *appLocalizationNotLoaded [animated]="true" style="width: 60%"></ion-skeleton-text>
        <ion-label *appLocalizationIsLoaded>{{ item.acronym | translate }}</ion-label>
      </ion-item>
      <ion-item
          routerDirection="root"
          routerLinkActive="active"
          routerLink="/support">
        <ion-icon slot="start" name="menu-support"></ion-icon>
        <ion-label>
          <ion-skeleton-text *appLocalizationNotLoaded [animated]="true" style="width: 60%"></ion-skeleton-text>
          <span *appLocalizationIsLoaded>{{ 'support.title' | translate }}</span>
          <ion-badge *ngIf="unreadSupportMsg$ | async" color="danger">
            {{ unreadSupportMsg$ | async }}
          </ion-badge>
        </ion-label>
      </ion-item>
      <ion-item
          routerDirection="root"
          routerLinkActive="active"
          routerLink="/settings">
        <ion-icon slot="start" name="menu-settings"></ion-icon>
        <ion-skeleton-text *appLocalizationNotLoaded [animated]="true" style="width: 60%"></ion-skeleton-text>
        <ion-label *appLocalizationIsLoaded>
          {{ 'settings.title' | translate }}
        </ion-label>
      </ion-item>
    </ion-menu-toggle>
  </ion-list>

  <ion-list lines="none">
    <ion-button
        *appPermission="[ roles.BCC_LEGAL_PERSON, roles.BCC_INDIVIDUAL_PERSON ]"
        shape="round" fill="outline" color="primary" expand="block"
        class="buttonBusinessAccount"
        (click)="openBusinessAccount()">
      <ion-skeleton-text *appLocalizationNotLoaded [animated]="true"
                         style="width: 60%; background-color: #3880ff; height: 5px;"></ion-skeleton-text>
      <span *appLocalizationIsLoaded>{{ 'main_navigation.open_business_account' | translate }}</span>
    </ion-button>

    <ion-button
        shape="round" fill="outline" color="danger" expand="block"
        class="buttonLogOut"
        (click)="onLogout()">
      <ion-skeleton-text *appLocalizationNotLoaded [animated]="true"
                         style="width: 60%; background-color: #f04141; height: 5px;"></ion-skeleton-text>
      <span *appLocalizationIsLoaded>{{ 'logOut.second_button' | translate }}&nbsp;&nbsp;→</span>
    </ion-button>
  </ion-list>
</div>
