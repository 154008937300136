import { CurrencyCode } from '../../models/payment.interfaces';
import { ROLES } from './users.roles';

type PaySystemType = 'MASTERCARD';
export type ProductType = 'MAIN' | 'ADDITIONAL' | 'KIDS' | 'FAMILY';
export type AccountStatusType = 'ACTIVE' | 'BLOCKED' | 'DEBIT_BLOCKED' | 'CLOSED' | 'IN_CLOSURE' | 'UNKNOWN';

export type CardStatusType = 'ACTIVE' | 'BLOCKED_BY_BANK' | 'LOCKED' | 'CARD_EXPIRED' | 'CLOSED_BY_BANK'  | 'CLOSED_BY_CUSTOMER'
  | 'LOST' | 'PLASTIC_IN_WAY' | 'STOLEN' | 'PENDING_SHIPMENT' | 'NOT_ISSUED' | 'UNKNOWN' | 'DEBIT_BLOCKED' | 'BLOCKED_BY_REGULATOR'
  | 'PROPOSED' | 'IN_CLOSURE' /* PROPOSED - need FE only for ordering cards */;

// TODO need switch CardsStatusType to enum
export enum CARD_STATUS_TYPE {
  active = 'ACTIVE'
}

export type CardLimitType = 'DAY' | 'MONTH' | 'FREE_ATM_WITHDRAWAL';
export type OperationType = 'PAYMENT_SEPA' | 'PAYMENT_SWIFT' | 'PAYMENT_CARD' | 'PAYMENT_CONTACT' | 'PAYMENT_CONTACT_ANY' /* only FE */
  | 'MONEY_REQUEST' | 'TOPUP_BY_CARD' | 'TOPUP_BY_TRANSFER' | 'TOPUP_BY_CODE' | 'TOPUP_BY_BINANCE' | 'CURRENCY_EXCHANGE';

export enum AccountType {
  PHYSICAL = 'PHYSICAL',
  JURIDICAL = 'JURIDICAL'
}

export enum ActiveBonusProgram {
  CASHBACK1 = 'CASH_BACK_PROGRAM_1',
  CASHBACK2 = 'CASH_BACK_PROGRAM_2',
  CASHBACK3 = 'WILDBERRIES_PROGRAM',
  CASHBACK_AMAZON = 'CASH_BACK_PROGRAM_4',
  CASHBACK_MOBILE_STORES = 'CASH_BACK_PROGRAM_5',
  CASHBACK_CARD_PURCHASES = 'CASH_BACK_PROGRAM_6',
  REWARD_4_PERCENT_10 = 'REWARD_4_PERCENT_10',
  REWARD_8_PERCENT_11 = 'REWARD_8_PERCENT_11',
  CASHBACK_1_PERCENT_13 = 'CASHBACK_1_PERCENT_13',
  CASHBACK_05_PERCENT_12 = 'CASHBACK_05_PERCENT_12',
  QUIZDOM_CASHBACK_9 = 'QUIZDOM_CASHBACK_9',
  QUIZDOM_PROMO_CODE_8 = 'QUIZDOM_PROMO_CODE_8',
  STREAMING_100_PERCENT_14 = 'STREAMING_100_PERCENT_14',
  BM_MARKET_3_PERCENT_16 = 'BM_MARKET_3_PERCENT_16',
  SPLIT_PAYMENTS_17 = 'SPLIT_PAYMENTS_17',
  ONLY_FANS_15_PERCENT_18 = 'ONLY_FANS_15_PERCENT_18'
}

export interface OrganizationBaseInfo {
  name: string;
  tin: string;
  title: string;
}

export interface Organization extends OrganizationBaseInfo {
  accounts: Array<Account>;
  cards: Array<Card>;
  client: Client;
  trustedClients: Array<TrustedClient>;
  clientName: string;
  id: number;
}

export interface Account {
  accountName: string;
  accountType: AccountType; // FE patched ???
  accruedInterest?: number; // FE patched
  activeBonusProgram: ActiveBonusProgram;
  activeBonusPrograms: Array<ActiveBonusProgram>;
  availableBalance?: number;
  availableOperations: Array<OperationType>;
  balance?: number;
  balanceHold?: number;
  balances: Array<AccountCurrencyBalance>;
  bonusProgramActivate: boolean;
  cards?: Array<Card>;
  clientId?: string;
  clientName?: string;
  clientTitle?: string;
  color: string;
  corporateRole: 'OWNER' | 'CUSTOMER';
  createdAt?: string;
  currency: CurrencyModel;
  id: string;
  limits?: Array<any>;
  number: string;
  organization: AccountOrganization;
  partnersAccounts: Array<PartnersAccountsModel>;
  status: AccountStatusType;
  title: string;
}

export interface PartnersAccountsModel {
  partnerName: string;
  accountId: string;
  accountName: string;
  accountNumber: string;
  currency: string;
  primary: null | boolean;
  productType: null | string;
}

export interface AccountCurrencyBalance {
  currency: CurrencyCode;
  balance: number;
  balanceHold?: number;
  availableBalance?: number;
}

export interface CurrencyModel {
  code: CurrencyCode;
}

interface AccountOrganization {
  id: number;
  name: string;
  tin: string;
  title: string;
}

export interface CardOrder extends Partial<Card> {
  permission: Array<ROLES>;
  availableAccountType: Array<AccountType>;
}

export interface Card {
  accountId: string;
  availableBalance: number;
  backgroundImage: null;
  balance: number;
  balanceHold: number;
  balances: Array<AccountCurrencyBalance>;
  clientId: string;
  createdAt: string;
  currency: CurrencyModel;
  expireAt: string;
  id: string;
  limits: Array<CardLimit>;
  number: string;
  options: CardOptions;
  owner: {
    embossedName: string;
  };
  paySystem: PaySystemType;
  plasticStatus: CardStatusType;
  productType: ProductType;
  status: CardStatusType;
  title: string;
  corporateRole?: 'OWNER' | 'CUSTOMER';
}

export interface CardLimit {
  maxValue: number;
  preferredMaxValues: Array<number>;
  title: string;
  type: CardLimitType;
  usedValue: number;
  maxLimitValue: number;
}

export interface CardOptions {
  limits: {
    disable: boolean;
    disable5min: null;
    disableOneTime: null;
  };
}

export interface Client {
  address: ClientAddress;
  citizenship: string;
  email: string;
  id: string;
  name: string;
  phoneNumber: string;
}

export interface TrustedClient {
  clientId: string;
  name: string;
  title: string;
  color: string;
  // id?: string;
}

export interface ClientAddress {
  apartmentNumber: string;
  city: string;
  country: string;
  countryCode: string;
  postalCode: string;
  province: string;
  street: string;
  streetNumber: string;
}

export interface ClientBlockingReasonResponse {
  requirements: Array<ClientBlockingReason>;
}

export interface ClientBlockingReason {
  blockReason: 'SELF_DECLARATION';
  infoNeeded: 'SELF_DECLARATION';
  message: string;
  referenceId: string;
}
