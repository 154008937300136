import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { clone } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { concatAll, filter, finalize, first, map, pluck, switchMap, tap, toArray } from 'rxjs/operators';

import { AuthManagerService } from '../auth/auth-manager.service';
import { StorageService } from '../../services/storage/storage.service';
import { PermissionService } from '../permission/permission.service';
import { ROLES } from '../users.roles';

import {
  Account, AccountStatusType, AccountType, Card, CardOrder, CardStatusType, Client, ClientBlockingReasonResponse, OperationType,
  Organization, OrganizationBaseInfo, TrustedClient
} from '../organizations.interface';
import { AppGtmService } from 'app/tools/app.gtm.service';


const ORDER_CARDS: CardOrder[] = [
  {
    productType: 'KIDS',
    status: 'PROPOSED',
    number: '5277 60',
    permission: [ ROLES.BCC_INDIVIDUAL_PERSON, ROLES.BCC_LEGAL_PERSON ],
    availableAccountType: [ AccountType.PHYSICAL ]
  }, {
    productType: 'FAMILY',
    status: 'PROPOSED',
    number: '5277 60',
    permission: [ ROLES.BCC_INDIVIDUAL_PERSON, ROLES.BCC_LEGAL_PERSON ],
    availableAccountType: [ AccountType.PHYSICAL ]
  }, {
    productType: 'ADDITIONAL',
    status: 'PROPOSED',
    number: '5277 60',
    permission: [ ROLES.BCC_INDIVIDUAL_PERSON, ROLES.BCC_LEGAL_PERSON, ROLES.GEKKARD ],
    availableAccountType: [ AccountType.PHYSICAL ]
  }
];

const NOT_DISPLAYED_ACCOUNT_STATUSES: Array<AccountStatusType> = [
  'CLOSED'
];

const NOT_DISPLAYED_CARD_STATUSES: Array<CardStatusType> = [
  'CLOSED_BY_CUSTOMER',
  'CLOSED_BY_BANK',
  'CARD_EXPIRED'
];

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {

  private availableOrganizationSource = new ReplaySubject<Organization[]>(1);
  availableOrganizations$: Observable<Organization[]> = this.availableOrganizationSource.asObservable();

  private activeOrganizationsIdsSource = new ReplaySubject<number[]>(1);
  activeOrganizationsIds$: Observable<number[]> = this.activeOrganizationsIdsSource.asObservable();

  private isLoadingSource = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this.isLoadingSource.asObservable();

  private activeTrustedClientsSource = new BehaviorSubject<TrustedClient[]>([]);
  activeTrustedClients$: Observable<TrustedClient[]> = this.activeTrustedClientsSource.asObservable();

  private static getOrganizationIds(organizations: Array<Organization>): Array<number> {
    if (organizations && organizations.length) {
      return organizations.map(organization => organization.id);
    }
  }

  static isBankCard(bankCard: Card | CardOrder): bankCard is Card {
    return (bankCard as Card).id !== undefined;
  }

  constructor(
    private http: HttpClient,
    private authManager: AuthManagerService,
    private storage: StorageService,
    private permission: PermissionService,
    private gtmService: AppGtmService) {

    this.authManager.bankToken$
      .pipe(
        filter(token => {
          if (!token) { this.resetOrganizations(); }
          return !!token;
        }),
        tap(() => {
          if (environment.production) {
            this.setAnalyticsData();
          }
          this.getStorageOrganizationData()
            .pipe(
              filter(organizations => !!organizations && organizations.length !== 0)
            )
            .subscribe(value => this.setOrganizations(value));
        }),
        switchMap(() => this.requestOrganizations()),
      )
      .subscribe(response => this.setOrganizations(response));
  }

  private setAnalyticsData(): void {
    this.getClientInfo()
      .pipe(filter(Boolean))
      .subscribe((client: Client) => {
        this.gtmService.sendGtag('config', environment.analytics.measurement_id, {
          user_id: client.id
        });
      });
  }

  /* Client */

  getClientInfo(): Observable<Client> {
    return this.getActiveOrganizationList().pipe(
      map(organizations => (organizations && organizations[0]) ? organizations[0].client : null)
    );
  }

  getClientBlockingReason(): Observable<ClientBlockingReasonResponse> {
    const url = environment.baseApi + '/v2/organizations/block-reason';
    return this.http.get<ClientBlockingReasonResponse>(url);
  }

  /* Trusted client */

  getTrustedClientList(): Observable<TrustedClient[]> {
    return this.getActiveOrganizationList().pipe(
      map(organizations => {
        return organizations.reduce((acc: Array<TrustedClient>, next: Organization) => {
          return acc.concat(next.trustedClients);
        }, []);
      }),
    );
  }

  getActiveTrustedClientList(): Observable<TrustedClient[]> {
    return this.activeTrustedClients$.pipe(
      filter(clientList => !!clientList && !!clientList.length),
      first()
    );
  }

  /*getTrustedClientByAccountNumber(accountNumber: string): Observable<TrustedClient> {
    return this.getAccountListForOrganizations().pipe(
      map(accountList => this.getAccountByNumber(accountList, accountNumber)),
      switchMap(account => this.getTrustedClientByAccount(account))
    );
  }*/

  getTrustedClientByAccount(account: Account): Observable<TrustedClient> {
    return this.getTrustedClientList().pipe(
      filter(() => !!account),
      map(clients => {
        return clients.find(client => client.clientId === account.clientId);
      })
    );
  }

  getTrustedClientByAccountId(accountId: string): Observable<TrustedClient> {
    return this.getAccountListForOrganizations().pipe(
      map(accountList => accountList.find(account => account.id === accountId)),
      switchMap(account => this.getTrustedClientByAccount(account))
    );
  }

  setActiveTrustedClients(activeClientList: Array<TrustedClient>): void {
    this.getTrustedClientList()
      .subscribe(clientList => {
        const result = activeClientList.map(activeClient => {
          return clientList.find(client => client.clientId === activeClient.clientId);
        });
        this.activeTrustedClientsSource.next(result);
      });
  }

  updateActiveTrustedClients(client: TrustedClient): void {
    const activeClientList  = this.activeTrustedClientsSource.getValue();
    const clientIndex = activeClientList.indexOf(client);
    const cloneClient = clone(client);
    activeClientList.splice(clientIndex, 1, cloneClient);
    this.setActiveTrustedClients(activeClientList);
  }

  updateTrustedClientRequest(client: TrustedClient): Observable<TrustedClient> {
    const url = environment.baseApi + `/v1/trusted-companies`;
    return this.http.post<TrustedClient>(url, client);
  }

  /* Organizations */

  private getActiveOrganizationList(): Observable<Organization[]> {
    return combineLatest([
      this.availableOrganizations$,
      this.activeOrganizationsIds$
    ])
      .pipe(
        filter(([ organizations, organizationIdList ]) => !!organizations.length && !!organizationIdList.length),
        first(),
        map(([ organizations, activeOrganizationIdList ]) => {
          return activeOrganizationIdList.map(accountId => {
            return organizations.find(organization => organization.id === accountId);
          });
        })
      );
  }

  getOrganizationId(accountId: string): Observable<number> {
    return this.availableOrganizations$.pipe(
      concatAll(),
      filter(organization => {
        const findOrganization = organization.accounts.some(account => account.id === accountId);
        if (!findOrganization) { console.warn('Not found organization by account Id'); }
        return findOrganization;
      }),
      first(),
      pluck('id')
    );
  }

  getOrganizationInfo(organizationId: number): Observable<OrganizationBaseInfo> {
    return this.getActiveOrganizationList().pipe(
      concatAll(),
      filter(organization => organization.id === organizationId),
      map(organization => {
        return { tin: organization.tin, title: organization.title, name: organization.name };
      })
    );
  }

  /* Accounts */

  getAccountListForOrganizations(): Observable<Account[]> {
    return this.getActiveOrganizationList().pipe(
      map(organizations => {
        return organizations.reduce((acc: Array<Account>, next: Organization) => {
          return acc.concat(next.accounts);
        }, []);
      })
    );
  }

  getAccountListForClients(clientList: Array<TrustedClient>): Observable<Account[]> {
    return this.getAccountListForOrganizations().pipe(
      map(accountList => accountList.filter(
        account => clientList.some(client => client.clientId === account.clientId))
      )
    );
  }

  getAccountListByAvailableOperation(operation: OperationType | OperationType[], clientList?: TrustedClient[]): Observable<Account[]> {
    return (
      (clientList)
        ? this.getAccountListForClients(clientList)
        : this.getAccountListForOrganizations()
    )
      .pipe(
        concatAll(),
        filter(account => {
          return (operation)
            ? (operation instanceof Array)
              ? account.availableOperations.some(a => operation.some(b => b === a))
              : account.availableOperations.some(a => a === operation)
            : true;
        }),
        toArray()
        /*map(accountList => accountList.filter(account => {
          // return (operation instanceof Array)
          //   ? account.availableOperations.some(a => operation.some(b => b === a))
          //   : account.availableOperations.some(a => a === operation);
        }))*/
      );
  }

  getAccountByAccountId(accountId?: string): Observable<Account> {
    return this.getAccountListForOrganizations().pipe(
      map(accountList => this.findAccountByAccountId(accountList, accountId))
    );
  }

  filterAccountListByClientId(accountList: Array<Account>, clientId: string): Array<Account> {
    return accountList.filter(account => account.clientId === clientId);
  }

  findAccountByAccountId(accountList: Array<Account>, accountId: string): Account {
    return accountList.find(account => account.id === accountId);
  }

  removeUnusedAccounts(accountList: Array<Account>): Array<Account> {
    return accountList.filter(account => !NOT_DISPLAYED_ACCOUNT_STATUSES.some(accountStatus =>  accountStatus === account.status));
  }

  /* Cards */

  getCardListForOrganizations(): Observable<Card[]> {
    return this.getActiveOrganizationList().pipe(
      map(organizations => {
        return organizations.reduce((acc: Array<Card>, next: Organization) => {
          return acc.concat(next.cards);
        }, []);
      })
    );
  }

  getCardById(cardId: string): Observable<Card> {
    return this.getCardListForOrganizations().pipe(
      map(cardList => this.findCardById(cardList, cardId))
    );
  }

  getCardListForClients(clientList: Array<TrustedClient>): Observable<Card[]> {
    return this.getCardListForOrganizations().pipe(
      map(cardList => cardList.filter(card => clientList.some(client => client.clientId === card.clientId)))
    );
  }

  findCardById(cardList: Array<Card>, cardId: string): Card {
    return cardList.find(card => card.id === cardId);
  }

  filterCardListByClientId(cardList: Array<Card>, clientId: string): Array<Card> {
    return cardList.filter(card => card.clientId === clientId);
  }

  filterCardListByAccountId(cardList: Array<Card>, accountId: string): Array<Card> {
    return cardList.filter(card => card.accountId === accountId);
  }

  /* TODO need implement */
  filterCardListBy(cardList: Array<Card>): Array<Card> {
    return cardList.filter(card => card);
  }

  getAdditionalCardsForBank(bankRole: ROLES, additionalCardList: CardOrder[]): CardOrder[] {
    return additionalCardList.filter(card => card.permission.some(role => role === bankRole));
  }

  pushOrderCards(cardList: Array<Card>): Observable<Card[]> {
    const cloneCardList = clone(cardList);
    return this.permission.getActiveBankRole().pipe(
      map(bankRole => this.getAdditionalCardsForBank(bankRole, ORDER_CARDS)),
      map(orderCardList => cloneCardList.concat(orderCardList as Card[]))
    );
  }

  private removeUnusedCards(cardList: Array<Card>): Array<Card> {
    return cardList.filter(card => !NOT_DISPLAYED_CARD_STATUSES.some(cardStatus => card.status === cardStatus));
  }

  /* Organizations */
  getOrganizationByCard(card: Card): Observable<Organization> {
    return this.availableOrganizations$.pipe(
      first(),
      concatAll(),
      filter(organization => organization.cards.some(orgCard => orgCard.id === card.id))
    );
  }

  // updateCardInsideOrganization(newCard: Card): void {
  //   this.availableOrganizations$
  //     .pipe(
  //       first(),
  //       // TODO using object mutation, need fix
  //       map(organizationList => {
  //         const organization = organizationList.find(a => a.cards.some(b => b.id === newCard.id));
  //         const organizationIndex = organizationList.indexOf(organization);
  //         const existingCard = organization.cards.find(a => a.id === newCard.id);
  //         const existingCardIndex = organization.cards.indexOf(existingCard);
  //         newCard.clientId = existingCard.clientId;
  //         const organizationCloned = clone(organization);
  //         organizationCloned.cards[existingCardIndex] = newCard;
  //         organizationList[organizationIndex] = organizationCloned;
  //         // organizationList[organizationIndex].cards[existingCardIndex] = newCard;
  //         return organizationList;
  //       }),
  //     )
  //     .subscribe(organizationList => this.setOrganizations(organizationList));
  // }

  updateCardInsideOrganizationV2(card: Card): Observable<Organization[]> {
    return this.getOrganizationByCard(card)
      .pipe(
        map(organization => {
          const existingCard = organization.cards.find(orgCard => orgCard.id === card.id);
          const existingCardIndex = organization.cards.indexOf(existingCard);
          card.clientId = existingCard.clientId;
          organization.cards.splice(existingCardIndex, 1, card);
          return organization;
        }),
        switchMap(organization => this.updateOrganization(organization)),
      );
  }

  setOrganizations(value: Array<Organization>): void {
    const organizations = this.normalizeOrganizationModel(value);
    const organizationsIds = OrganizationsService.getOrganizationIds(organizations);
    if (organizationsIds && organizationsIds.length) {
      this.activeOrganizationsIdsSource.next(organizationsIds);
      this.updateOrganizations(organizations);
      this.getTrustedClientList()
        .subscribe(clientList => this.setActiveTrustedClients(clientList));
    } else {
      console.warn('Not found active organizations');
    }
  }

  updateOrganization(organization: Organization): Observable<Organization[]> {
    return this.availableOrganizations$.pipe(
      first(),
      map(organizations => {
        const organizationIndex = organizations.findIndex(storedOrganization => storedOrganization.id === organization.id);
        organizations.splice(organizationIndex, 1, clone(organization));
        return organizations;
      }),
    );
  }

  updateOrganizations(value: Array<Organization>): void {
    this.availableOrganizationSource.next(value);
    this.setStorageOrganizationData(value)
      .subscribe(() => console.log('organization model is synchronized'));
  }

  resetOrganizations() {
    this.activeOrganizationsIdsSource.next([]);
    this.availableOrganizationSource.next([]);
    this.activeTrustedClientsSource.next([]);
  }

  requestOrganizations(): Observable<Organization[]> {
    const url = environment.baseApi + `/v2/organizations`;
    // const url = '/assets/mocks/organizations-check-client.json';
    this.setLoadingStatus(true);
    return this.http.get<Organization[]>(url).pipe(
      finalize(() => this.setLoadingStatus(false))
    );
  }

  private normalizeOrganizationModel(organizations: Array<Organization>): Array<Organization> {
    return organizations.map(organization => {
      organization.accounts = this.removeUnusedAccounts(organization.accounts);
      organization.cards = this.removeUnusedCards(organization.cards);
      return organization;
    });
  }

  private setStorageOrganizationData(value: Array<Organization>) {
    return this.storage.set('organizations', value);
  }

  private getStorageOrganizationData(): Observable<Organization[]> {
    return this.storage.get('organizations');
  }

  private setLoadingStatus(value: boolean): void {
    const currentValue = this.isLoadingSource.getValue();
    if (value !== currentValue) {
      this.isLoadingSource.next(value);
    }
  }

}
